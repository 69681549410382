<template>
<b-card>
  <validation-observer ref="productForm">
  <b-form @submit.prevent="saveUpdateChanges">
    <b-row>
        <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Nombre *">
               <validation-provider
                  #default="{ errors }"
                  name="nombre"
                  rules="required">
                  <b-form-input v-model="formData.name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

             <b-form-group label="Estatus *">
                <validation-provider
                  #default="{ errors }"
                  name="estatus"
                  rules="required">
                  <v-select
                    v-model="formData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="[{id: 1, name: 'Activo'}, {id: 0, name: 'Inactivo'}]"
                    label="name"
                    :reduce="(item) => item.id"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group label="Precio *">
                <validation-provider
                  #default="{ errors }"
                  name="precio"
                  rules="required">
                  <b-form-input v-model="formData.price"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            
            <b-form-group label="Stock Alerta Minimo *">
                <validation-provider
                  #default="{ errors }"
                  name="stock alerta minimo"
                  rules="required">
                  <b-form-input v-model="formData.stock_alert_min" type="number"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            
            <b-form-group label="Stock Alerta Maximo *">
                <validation-provider
                  #default="{ errors }"
                  name="stock alerta maximo"
                  rules="required">
                  <b-form-input v-model="formData.stock_alert_max" type="number"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
        </b-col>

        <b-col md="6" xl="4" class="mb-1">

            <b-form-group label="Descripción">
                <b-form-input v-model="formData.description"/>
            </b-form-group>

             <b-form-group label="Categoria *">
                <validation-provider
                  #default="{ errors }"
                  name="categoria"
                  rules="required">
                    <v-select
                      v-model="formData.category_product_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categoriesOptions"
                      label="name"
                      :reduce="(item) => item.id"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
             
             <b-form-group label="Stock *">
                <validation-provider
                  #default="{ errors }"
                  name="stock"
                  rules="required">
                  <b-form-input v-model="formData.stock" type="number"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
            
            <b-form-group label="Tipo de Descuento" label-for="type">
              <v-select
                v-model="formData.discount_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="discountOptions"
                label="label"
                :reduce="(item) => item.name"
              />
            </b-form-group>

            <b-form-group label="Descuento" label-for="Descuento" v-if="formData.discount_type">
              <validation-provider
                #default="{ errors }"
                name="stock"
                rules="required">
                <b-form-input v-model="formData.discount"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Colegios">
              <validation-provider
                #default="{ errors }"
                name="colegio"
                rules="required">
                  <v-select
                  v-model="formData.school_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="schoolOptions"
                  label="name"
                  :reduce="(item) => item.id"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
           
        </b-col>

        <b-col md="6" xl="4" class="mb-1">
          <b-media class="mb-2">
            <template #aside>
              <b-avatar
                ref="previewEl"
                :src="urlPreview"
                :variant="`light-success`"
                size="250px"
                rounded
              />
            </template>
            <div class="d-flex flex-wrap">
              <b-button variant="primary" @click="$refs.refInputEl.click()">
                <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="uploadImage">
                <feather-icon
                  icon="UploadIcon"
                  class="d-inline mr-sm-50"
                />
                <span class="d-none d-sm-inline">Subir</span>
              </b-button>
            </div>
          </b-media>
        </b-col>

        <b-row>
            <b-col class="mt-2 d-flex justify-content-end">
                <b-button
                type="submit"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                <!-- icon save -->
                <feather-icon
                    :icon="modeEdit ? 'EditIcon' : 'SaveIcon'"
                    size="16"
                    class="mr-50"
                />
                {{ modeEdit ? 'Actualizar': 'Guardar' }}
                </b-button>
            </b-col>
        </b-row>
    </b-row>
  </b-form>
  </validation-observer>
</b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BMedia, BAvatar, BFormSelect
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, } from '@validations'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
localize('es', es)

export default {
  components: {
    BMedia, 
    BAvatar,
    BCard, 
    BRow, 
    BCol, 
    BForm, 
    BFormGroup, 
    BFormInput, 
    BButton, 
    BInputGroup, 
    BInputGroupPrepend, 
    vSelect, 
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      schoolOptions: [],
      required,
      categoriesOptions: [],
      discountOptions: [{label: 'Porcentaje', name: 'percent'}, {label: 'Fijo', name: 'fixed'}],
      base64: null,
      urlPreview: null,
      formData: {
        name: null,
        description: null,
        status: 1,
        price: null,
        discount: null,
        discount_type: null,
        stock: null,
        stock_alert_min: null,
        stock_alert_max: null,
        category_product_id: null,
      }
    }
  },
  computed: {
    modeEdit() {
      if( router.currentRoute.name === 'products-edit' ) {
        return router.currentRoute.params.id
      }
    },
  },
  methods: {
    async saveUpdateChanges() {
      if(this.modeEdit) {
        this.update()
      } else {
        this.create()
      }
    },
    async create() {
      if (!await this.$refs.productForm.validate()) {
        return
      }

      if(this.base64) {
        this.formData.image = this.base64
      }

      await store.dispatch('products/store', this.formData)
      .then(() => {
        this.message('checkCircleIcon', 'success', 'Se registro el Producto exitosamente')
        this.$router.push({ name: 'products' })
      })
      .catch((error) => {
        this.message('AlertCircleIcon', 'danger', error.response.data.message)
      })
    },
    async update() {
      
      if(this.base64) {
        this.formData.image = this.base64
      }

      await store.dispatch('products/update', { id: this.modeEdit, data: this.formData})
      .then(() => {
        this.message('checkCircleIcon', 'success', 'Se actualizo el Producto exitosamente')
        this.$router.push({ name: 'products', params: { page_preview: this.$router.currentRoute.params.page_preview }})
      })
      .catch((error) => {
        this.message('AlertCircleIcon', 'danger', error.response.data.message)
      })
    },
    async getCategories() {
      this.categoriesOptions = await store.dispatch('products/index', {categories: true})
    },
    async getData(){
      if (this.modeEdit || this.modeView) {
        const res = await store.dispatch('products/show', this.modeEdit)
        
        
        this.urlPreview = res.data.image
        this.formData.name = res.data.name
        this.formData.description = res.data.description
        this.formData.status =  res.data.status
        this.formData.price = res.data.price
        this.formData.discount = res.data.discount
        this.formData.discount_type = res.data.discount_type
        this.formData.stock = res.data.stock
        this.formData.stock_alert_min = res.data.stock_alert_min
        this.formData.stock_alert_max = res.data.stock_alert_max
        this.formData.category_product_id = res.data.category_product_id
      }
    },
    uploadImage() {
      const { inputImageRenderer } = useInputImageRenderer(this.$refs.refInputEl, this.getImage)

      inputImageRenderer()
    },
    getImage(base64) {
      this.base64 = base64
      this.urlPreview = base64
    },
    message(icon, variant, text) {
      this.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: 'Respuesta',
        icon: icon,
        variant: variant,
        text: text,
      }})
    },
    async getSchools() {
      this.schoolOptions = await this.$store.dispatch('schools/index', {schools: true})
    },
  },
  mounted(){
    console.log(this.$router.currentRoute)
    this.getCategories()
    this.getData()
    this.getSchools()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>