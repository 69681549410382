var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"productForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveUpdateChanges.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Nombre *"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Estatus *"}},[_c('validation-provider',{attrs:{"name":"estatus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":[{id: 1, name: 'Activo'}, {id: 0, name: 'Inactivo'}],"label":"name","reduce":function (item) { return item.id; }},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Precio *"}},[_c('validation-provider',{attrs:{"name":"precio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", $$v)},expression:"formData.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Stock Alerta Minimo *"}},[_c('validation-provider',{attrs:{"name":"stock alerta minimo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.formData.stock_alert_min),callback:function ($$v) {_vm.$set(_vm.formData, "stock_alert_min", $$v)},expression:"formData.stock_alert_min"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Stock Alerta Maximo *"}},[_c('validation-provider',{attrs:{"name":"stock alerta maximo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.formData.stock_alert_max),callback:function ($$v) {_vm.$set(_vm.formData, "stock_alert_max", $$v)},expression:"formData.stock_alert_max"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Descripción"}},[_c('b-form-input',{model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1),_c('b-form-group',{attrs:{"label":"Categoria *"}},[_c('validation-provider',{attrs:{"name":"categoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoriesOptions,"label":"name","reduce":function (item) { return item.id; }},model:{value:(_vm.formData.category_product_id),callback:function ($$v) {_vm.$set(_vm.formData, "category_product_id", $$v)},expression:"formData.category_product_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Stock *"}},[_c('validation-provider',{attrs:{"name":"stock","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.formData.stock),callback:function ($$v) {_vm.$set(_vm.formData, "stock", $$v)},expression:"formData.stock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tipo de Descuento","label-for":"type"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.discountOptions,"label":"label","reduce":function (item) { return item.name; }},model:{value:(_vm.formData.discount_type),callback:function ($$v) {_vm.$set(_vm.formData, "discount_type", $$v)},expression:"formData.discount_type"}})],1),(_vm.formData.discount_type)?_c('b-form-group',{attrs:{"label":"Descuento","label-for":"Descuento"}},[_c('validation-provider',{attrs:{"name":"stock","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.discount),callback:function ($$v) {_vm.$set(_vm.formData, "discount", $$v)},expression:"formData.discount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3364081850)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Colegios"}},[_c('validation-provider',{attrs:{"name":"colegio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.schoolOptions,"label":"name","reduce":function (item) { return item.id; }},model:{value:(_vm.formData.school_id),callback:function ($$v) {_vm.$set(_vm.formData, "school_id", $$v)},expression:"formData.school_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.urlPreview,"variant":"light-success","size":"250px","rounded":""}})]},proxy:true}])},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file"},on:{"input":_vm.uploadImage}}),_c('feather-icon',{staticClass:"d-inline mr-sm-50",attrs:{"icon":"UploadIcon"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Subir")])],1)],1)])],1),_c('b-row',[_c('b-col',{staticClass:"mt-2 d-flex justify-content-end"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"type":"submit","variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":_vm.modeEdit ? 'EditIcon' : 'SaveIcon',"size":"16"}}),_vm._v(" "+_vm._s(_vm.modeEdit ? 'Actualizar': 'Guardar')+" ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }